import { css } from '@emotion/react'

import { mTheme } from '../style/themes'

const styles: Styles = {
  container: css({
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    gap: mTheme.size.XS,
    padding: mTheme.size.S,
    borderRadius: mTheme.radius.primary,
    border: `2px solid`,
    backgroundColor: mTheme.backgroundColor.secondary,
    borderColor: mTheme.borderColor.secondary,
  }),
  success: css({
    borderColor: mTheme.color.success,
    color: mTheme.color.success,
  }),
  warning: css({
    borderColor: mTheme.color.warning,
    color: mTheme.color.warning,
  }),
  error: css({
    borderColor: mTheme.color.alert,
    color: mTheme.color.alert,
  }),

  content: css({
    margin: 0,
  }),
}

export type CalloutVariant = 'success' | 'warning' | 'error' | 'plain'

interface CalloutProps {
  children: React.ReactNode
  variant?: CalloutVariant
}

export function Callout(props: CalloutProps) {
  const variant = props.variant ?? 'plain'

  const icons: Record<CalloutVariant, string> = {
    success: 'fas fa-check',
    warning: 'fas fa-exclamation-triangle',
    error: 'fas fa-exclamation-circle',
    plain: 'fas fa-info-circle',
  }

  return (
    <div css={[styles.container, styles[variant]]}>
      <i className={icons[variant]} />
      <p css={styles.content}>{props.children}</p>
    </div>
  )
}
